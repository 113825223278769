.header {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: fixed;
}
.header-logo img {
  height: 75px;
  width: 100%;
}
.section-padding {
  background: #f1f9f9;
}
.tutor-list .section-padding {
  background: #fff;
}
.home .section-padding {
  background: none;
}
.login-register-box {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.single-testimonial-content p {
  font-size: 14px !important;
  color: #444 !important;

  font-weight: 400;
  line-height: 1.66;
  color: #072f60;
}
.mt-70 {
  margin-top: 70px;
}
.location-f {
  margin-top: 20px;
}
.range-f {
  margin-top: 20px;
}
.range-f input {
  width: 100%;
  background-color: #3bbca7 !important;
}
.range-f .form-control-range {
  background-color: #3bbca7 !important;
}
.widget-title {
  font-size: 15px !important;
  font-weight: 700;
}
.range-flex {
  display: flex;
  justify-content: space-between;
  padding: 0px 5px;
}
.course-top-bar {
  margin-top: 20px;
  margin-bottom: 25px;
}
.range-flex span {
  font-weight: 500;
  font-size: 14px;
  color: #3bbca7;
}

.location-f .form-group {
  margin-bottom: 20px;
}
.location-f .form-control {
  font-size: 15px !important;
}
.location-f .form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #ced4da !important;
  outline: 0;
  box-shadow: none !important;
}
.sidebar-wrap-02 {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px !important;
  background-color: #fff !important;
  border-radius: 10px;
  padding: 20px 40px;

  margin-bottom: 25px;
}
.widgets .widget-title {
  background-color: #f9f9f9;
  padding: 10px 20px;
  margin-bottom: 0;
}
.widgets .widget-content {
  padding: 15px 20px;
  border-top: 1px solid #eaeaea;
}
.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}
.single-category-item a .title {
  font-size: 14px !important;
  font-weight: 600;
  color: #072f60;
  line-height: 1.3;
  transition: all 0.3s linear;
}
.section-title p {
  font-size: 15px !important;
  font-weight: 400;
  padding-top: 10px;
}
.single-testimonial-content .name {
  margin-top: 10px !important;
}
.profile-section {
  background: #f1f9f9;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding-top: 70px;
  display: flex;
  gap: 10px;
}
.sidebar-menu {
  width: 300px;
  background-color: #fff;
  height: 100%;
  padding-top: 20px;
}
.main-profile-content {
  margin: 20px 0px;
  width: 100%;
}
.form-group label {
  color: #999;
}
input,
select,
textarea {
  color: #666 !important;
}
.main-profile-content .card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.alert-box {
  position: fixed;
  top: 120px;
  right: 0px;
  z-index: 999;
  margin: 0 auto;
}
.auth-menu {
  position: absolute;
  top: 25px;
  background-color: #fff;
  padding: 10px 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 5px;
}
.auth-menu li {
  padding: 10px 10px;
}
.header-login {
  position: relative;
}
.steps {
  display: flex;
  justify-content: space-between;
  gap: 25px;
}
.step-item a {
  text-align: center;
  background-color: #f1f9f9;
  padding: 20px 100px;
  border-radius: 5px;
}
.steps .active-step a {
  background-color: #3bbca7 !important;
  color: #fff;
}
.tution-flex i {
  margin-left: 20px;
  color: #3bbca7;
}
.author-name span {
  margin-right: 10px;
}
.gap-20 {
  gap: 20px;
}

.single-card-flex {
  display: flex;
  gap: 25px;
}
.tutor-name h3 {
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.single-list {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px !important;
  background-color: #fff !important;
  margin-bottom: 20px;
  padding: 40px 40px;
  background: #fff;
}
.star-rating i {
  margin-left: 5px;
  color: #ffdf00;
}
.tution-flex {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  justify-content: flex-start;
}
.single-course-list .course-image a img {
  height: 250px;
  width: 250px;
  object-fit: contain;
}
.sidebar-details-video-description .sidebar-video img {
  height: 250px;
  width: 100%;
  object-fit: cover;
}
.form-group {
  margin-bottom: 10px;
}
.experience-flex {
  display: flex;
  padding-top: 10px;
  justify-content: space-between;
}
.graduate-f {
  display: flex;
  gap: 25px;
  flex: 1;
}
.multiselect {
  border: 2px solid #f1f1f1;
  padding: 10px 10px;
}
.taught {
  margin-top: 10px;
}
.checkbox-custom {
  border: 1px solid #3bbca7;
  margin: 5px;
  text-align: center;
  padding: 20px 10px;
  border-radius: 20px;
}
.checkbox-custom span {
  color: #666;
}
.subject-list {
  display: flex;
  gap: 20px;
  padding: 20px 20px;
}
.graduate-f h3 {
  font-size: 14px;
  font-weight: 400;
  color: #666;
}
.graduate-f img {
  height: 50px;
}
.graduate-f h5 {
  font-size: 16px;
  font-weight: 600;
  color: #1b1b1b;
}
.rate-flex {
  display: flex;
  padding-top: 20px;
  gap: 50px;
}
.btn-profile {
  background: #f3f3f3;
  font-size: 14px !important;
}
.btn-call {
  background: #3bbca7 !important;
  font-size: 14px !important;
  padding: 10px 40px;
  color: #fff;
}
.bg-f {
  background: #3bbca7;
  color: #fff;
}
.bg-f:hover {
  color: #fff;
}
.rupees {
  padding-top: 10px;
}
.btn-call:hover {
  color: #fff;
}
.subject-single {
  border: 1px solid #3bbca7;
  color: #3bbca7;
  padding: 10px 20px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.sidebar-menu li {
  padding: 10px 10px;
}
.sidebar-header {
  padding: 10px 10px;
  border-bottom: 1px solid #f1f1f1;
  color: #666;
}
.sidebar-menu .active {
  background-color: #f1f1f8;
  color: #3bbca7;
}
.single-banner {
  background: url("../../assets/images/banner-shapes.jpg") center center;
  position: relative;
  /* min-height: 500px; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-top: 80px;
  padding-bottom: 80px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
.banner-content {
  position: relative;
}
.banner-content h3 {
  color: #fff;
  font-size: 20px;
  padding-bottom: 15px;
}
.banner-content h2 {
  color: #fff;
  font-size: 25px;
}
.single-banner .breadcrumb {
  background: transparent;
  margin-bottom: 0;
  position: relative;
  padding: 0;
}
.single-banner .breadcrumb .breadcrumb-item {
  font-size: 13px;
  font-weight: 600;
  padding-left: 10px;
}
.breadcrumb-item {
  display: -ms-flexbox;
  display: flex;
}
.breadcrumb-item i {
  margin-right: 5px;
}
.single-banner .breadcrumb .breadcrumb-item a {
  color: #3bbca7;
}
.single-banner .breadcrumb .breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: none;
}
.ptb-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.single-tutor-information {
  border: 1px solid #f1f1f1;
}
.single-tutor-information img {
  max-width: 100%;
}
.information-single-flex {
  display: flex;
  padding: 15px 20px;

  gap: 45px;
  justify-content: space-between;
  border-bottom: 1px solid #f1f1f1;
}
.icon-single img {
  height: 25px;
  width: auto;
}

.icon-single h3 {
  font-size: 16px;
  color: #1b1b1b;
  font-weight: 700;
}
.ic-f {
  display: flex;
  gap: 5px;
}
.icon-single-value {
  flex: 1;
}
.ic-f {
  flex: 1;
}
.icon-single-value h3 {
  font-size: 14px;

  padding: 0px;
  margin-left: auto;
  margin-right: auto;

  font-weight: 500;
  color: #666;
}
.single-tutor-information {
  padding: 20px;
  position: sticky;
  top: 80px;
}
.single-category table {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}
.single-category table thead {
  background: #3bbca7;
}
.single-category table thead th {
  color: #fff;
}
.single-category table tbody tr {
  margin-bottom: 20px;
}
.single-category .table-striped tbody tr td {
  color: #666666;
  border-top-color: #eaeaea;
  font-weight: 400;
}
.following h3 {
  font-size: 18px;
}
.list {
  margin-top: 10px;
}
.list li {
  font-size: 14px;
  margin-bottom: 10px;
}
.list li i {
  margin-right: 10px;
}
.following p {
  font-size: 14px;
}
.tutor-available {
  margin-top: 15px;
}
.tutor-available h3 {
  margin-bottom: 10px;
}
.main-single-tu {
  background: #f9f9f9;
  padding: 40px;
}
.travelling i {
  display: inline-block;
  margin-right: 15px;
  color: #3bbca7;
}
.travelling h3 {
  font-size: 18px;
}
.travelling p {
  color: #666;
  font-size: 14px;
}
.travelling {
  margin-bottom: 50px;
}
.tutor-available h3 {
  font-size: 18px;
}
.single-tutor-informationss {
  padding: 30px;
}
.tutor-available {
  margin-bottom: 25px;
}
.share-pr .social {
  display: flex;
  margin-top: 20px;
}
.share-pr .social li {
  margin-right: 10px;
}
.share-pr .social li a {
  width: 35px;
  height: 35px;
  line-height: 33px;
  border: 1px solid #666666;
  text-align: center;
  border-radius: 50%;
  color: #666666;
}
.share-pr .social li a:hover {
  background-color: #3bbca7;
  border-color: #3bbca7;
  color: #ffffff;
}

.add-review h3 {
  font-size: 18px;
}
.add-review p {
  color: #666;
  font-size: 14px;
}
.star-fill ul li {
  display: inline-block;
  margin-right: 10px;
}
.write-review {
  margin-top: 10px;
}
.add-review {
  margin-bottom: 10px;
}
