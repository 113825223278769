.fr-de {
  margin-right: 20px;
}
.slider-content {
  padding-top: 0px !important;
  max-width: 520px;
}
.single-category-item a .title {
  font-size: 20px !important;
  font-weight: 600;
  color: #072f60;
  line-height: 1.3;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.ht {
  margin-top: 20px;
}
.location .item-icon {
  height: 35px;
  width: auto;
}
.location a .title {
  font-size: 15px !important;
}
.location a {
  height: 70px !important;
  line-height: 75px !important;
  padding-left: 90px !important;
}
.single-event .event-content .title {
  font-size: 22px !important;
  font-weight: 700;
}
.item-icon {
  height: 50px;
  width: auto;
}
.bg-1 {
  background: #eeeff4 !important;
}
.bg-2 {
  background: #f4e9ef !important;
}
.bg-3 {
  background: #eafffd !important;
}
.pp li {
  display: inline-block;
  margin-right: 20px;
}
.pp li a {
  font-size: 12px;
}
.language-flex {
  display: flex;
  justify-content: space-between;
  flex: 1;
  gap: 20px;
  margin-top: 45px;
}
.box-lang {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;

  width: 250px;
  padding: 30px;
}
.box-lang h2 {
  font-size: 20px;
  text-align: center;
}
.box-lang :hover {
  cursor: pointer;
}
