#powerTip {
    cursor: default;
    background-color: #333;
    background-color: rgba(0, 0, 0, .8);
    border-color: #333;
    border-color: rgba(0, 0, 0, .8);
    border-radius: 6px;
    color: #fff;
    display: none;
    padding: 10px;
    position: absolute;
    white-space: nowrap;
    z-index: 2147483647
}

#powerTip:before {
    content: attr(class) " ";
    position: absolute;
    height: 0;
    width: 0;
    text-indent: 100%;
    overflow: hidden
}

#powerTip.n:before,
#powerTip.s:before {
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    left: 50%;
    margin-left: -5px
}

#powerTip.e:before,
#powerTip.w:before {
    border-bottom: 10px solid transparent;
    border-top: 10px solid transparent;
    margin-top: -5px;
    top: 50%
}

#powerTip.n:before,
#powerTip.ne:before,
#powerTip.nw:before {
    bottom: -10px
}

#powerTip.n:before,
#powerTip.ne-alt:before,
#powerTip.ne:before,
#powerTip.nw-alt:before,
#powerTip.nw:before {
    border-top-color: inherit;
    border-top-style: solid;
    border-top-width: 10px
}

#powerTip.e:before {
    border-right-color: inherit;
    border-right-style: solid;
    border-right-width: 10px;
    left: -10px
}

#powerTip.s:before,
#powerTip.se:before,
#powerTip.sw:before {
    top: -10px
}

#powerTip.s:before,
#powerTip.se-alt:before,
#powerTip.se:before,
#powerTip.sw-alt:before,
#powerTip.sw:before {
    border-bottom-color: inherit;
    border-bottom-style: solid;
    border-bottom-width: 10px
}

#powerTip.w:before {
    border-left-color: inherit;
    border-left-style: solid;
    border-left-width: 10px;
    right: -10px
}

#powerTip.ne:before,
#powerTip.se:before {
    border-right: 10px solid transparent;
    border-left: 0;
    left: 10px
}

#powerTip.nw:before,
#powerTip.sw:before {
    border-left: 10px solid transparent;
    border-right: 0;
    right: 10px
}

#powerTip.ne-alt:before,
#powerTip.nw-alt:before,
#powerTip.se-alt:before,
#powerTip.sw-alt:before {
    bottom: -10px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    left: 10px
}

#powerTip.ne-alt:before {
    left: auto;
    right: 10px
}

#powerTip.se-alt:before,
#powerTip.sw-alt:before {
    border-top: none;
    bottom: auto;
    top: -10px
}

#powerTip.se-alt:before {
    left: auto;
    right: 10px
}